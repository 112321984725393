export const loginBox = {
  coloredBox: function (mycolor) {
    return {
      color: 'black',
      width: '100%',
      backgroundColor: mycolor,
      paddingTop: '1rem',
      paddingBottom: '1rem',
      paddingLeft: '5rem',
      paddingRight: '5rem',
      fontSize: 'calc(0.7rem + 0.7vw)',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    };
  },
};
export const headerBox1 = {
  display: 'flex',
  flexWrap: 'nowrap',
  width: '90%',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: '2rem',
  paddingBottom: '2rem',
  paddingLeft: '5%',
  paddingRight: '5%',
};
export const headerBox2 = {
  display: 'flex',
  flexWrap: 'wrap',
  paddingLeft: '5%',
  paddingRight: '0%',
  width: '90%',
  alignItems: 'center',
  backgroundColor: 'white',
  justifyContent: 'center',
  paddingTop: '2rem',
  paddingBottom: '2rem',
  borderBottom: '1px solid rgba(233, 235, 237, 1)',
};
export const headerBox3 = {
  display: 'flex',
  flexWrap: 'wrap',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'space-between',
};
export const footerBox1 = {
  paddingLeft: '10%',
  paddingRight: '10%',
  width: '80%',
  backgroundColor: 'white',
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'nowrap',
  paddingTop: '1rem',
  paddingBottom: '3rem',
  borderTop: '1px solid #D8DCDE',
};
export const wholeLoginPage = {
  width: '100%',
  // minWidth: '100vw',
  minHeight: '100vh',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};
export const wholeEnterpriseLoginPage = {
  width: '100%',
  minWidth: '100vw',
  minHeight: '100vh',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};
export const loginTitle1 = {
  marginTop: '10rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  fontWeight: 'normal',
  fontSize: 'calc(0.7rem + 0.7vw)',
  width: '40rem',
};
export const loginTitle2 = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  fontWeight: 'bold',
  fontSize: 'calc(1rem + 2vw)',
  width: '40rem',
};

export const loginKakaoBox = {
  marginTop: '3rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
export const loginNaverBox = {
  marginTop: '3rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '3rem',
};
export const loginGoogleBox = {
  marginTop: '3rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
export const loginEnterpriseBox = {
  marginTop: '3rem',
  fontSize: '0.875rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
};

export const EnterpriseLogoBox = {
  marginTop: '1rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
  fontSize: '2.5rem',
  color: '#516561',
};
export const EnterpriseInfoBox = {
  marginTop: '1rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
  fontSize: '0.874rem',
  color: '#516561',
};
export const EnterpriseTextFieldBox1 = {
  marginTop: '4rem',
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'center',
};
export const EnterpriseTextFieldBox2 = {
  marginTop: '1rem',
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'center',
};
export const EnterpriseErrorBox1 = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  marginBottom: '2rem',
  color: '#E8514D',
  fontSize: '1rem',
  minHeight: '1rem',
  marginTop: '0.5rem',
  marginLeft: '0.3rem',
};
export const EnterpriseErrorBox2 = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  marginBottom: '3rem',
  color: '#E8514D',
  fontSize: '1rem',
  minHeight: '1rem',
  marginTop: '0.5rem',
  marginLeft: '0.3rem',
};
export const EnterpriseNoticeBox = {
  marginTop: '1rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'end',
  justifyContent: 'center',
  marginBottom: '3rem',
  color: '#445854',
  maxWidth: '100rem',
};
export const EnterpriseEndBox = {
  marginTop: '1rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '3rem',
};
