import React, {useState} from 'react';
import {Box, Button, TextField} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {saveLogin} from 'common/reducer/auth';
import HeaderView from 'view/common/Header';
import {
  wholeEnterpriseLoginPage,
  EnterpriseLogoBox,
  EnterpriseInfoBox,
  EnterpriseTextFieldBox1,
  EnterpriseTextFieldBox2,
  EnterpriseErrorBox1,
  EnterpriseErrorBox2,
  EnterpriseNoticeBox,
  EnterpriseEndBox,
} from 'view/login/Styles';
import APIUtils from 'common/utils/APIUtils';
import FooterView from 'view/common/Footer';

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authReducer = useSelector(state => state.authReducer);
  const [IDError, setIDError] = useState(false);
  const [PWError, setPWError] = useState(false);

  const [idText, setIdText] = useState('');
  const handleIdText = event => {
    let inputVal = event.target.value;
    setIdText(inputVal);
  };
  const [pwText, setPwText] = useState('');
  const handlePwText = event => {
    let inputVal = event.target.value;
    setPwText(inputVal);
  };
  function showIDError() {
    if (IDError) {
      return <Box style={EnterpriseErrorBox1}>아이디를 확인하세요.</Box>;
    }
    return <Box style={EnterpriseErrorBox1}></Box>;
  }
  function showPWError() {
    if (PWError) {
      return <Box style={EnterpriseErrorBox2}>비밀번호를 확인하세요.</Box>;
    }
    return <Box style={EnterpriseErrorBox2}></Box>;
  }
  function callEnterpriseLoginAPI() {
    if (idText == '' && pwText == '') {
      setIDError(true);
      setPWError(true);
      return;
    } else if (idText == '') {
      setIDError(true);
      setPWError(false);
      return;
    } else if (pwText == '') {
      setIDError(false);
      setPWError(true);
      return;
    }
    const loginResult = async (id, pw) => {
      try {
        const response = await APIUtils.AccountLoginSchool(id, pw);

        return response;
      } catch (err) {
        console.log(err);
      }
    };
    loginResult(idText, pwText).then(r => {
      if (r.data.ret_code == 1000) {
        if (r.data.ret_code == 1000) {
          dispatch(
            saveLogin({
              user_type: r.data.user_type,
              student_id: r.data.student_id,
              student_name: r.data.student_name,
              student_email: r.data.student_email,
              path: 'school',
            }),
          );
          for (var i = 0; i < localStorage.length; i++) {
            var foundKey = localStorage.key(i);
            if (
              foundKey.includes('taskTitle') ||
              foundKey.includes('taskBody') ||
              foundKey.includes('task_id') ||
              foundKey.includes('practiceBody') ||
              foundKey.includes('practiceTitle') ||
              foundKey.includes('practiceJanre')
            ) {
              localStorage.removeItem(foundKey);
            }
          }
        }
        gotoMypage();
      } else if (r.data.ret_code == 2001) {
        setIDError(true);
        setPWError(false);
      } else if (r.data.ret_code == 2004) {
        setIDError(false);
        setPWError(true);
      } else if (r.data.ret_code == 2003) {
        alert('이미 탈퇴한 유저입니다.');
      } else if (r.data.ret_code == 9999) {
        alert('엔진에러');
      } else if (r.data.ret_code == 9998) {
        alert('입력값 에러');
      } else if (r.data.ret_code == 9997) {
        alert('DB 저장 에러');
      }
    });
  }
  function gotoMypage() {
    navigate('/mypage', {replace: true});
  }
  function onKeyPressed(ev) {
    if (ev.keyCode == 13) {
      callEnterpriseLoginAPI();
    }
  }
  return (
    <Box style={wholeEnterpriseLoginPage}>
      <HeaderView />
      <Box
        style={{
          minHeight: '55vh',
          width: '80%',
        }}
      >
        <Box style={EnterpriseLogoBox}>ID/PW 로그인</Box>
        <Box style={EnterpriseInfoBox}>
          {' '}
          비밀번호를 잊었다면, 연결된 선생님에게 알려주세요. 아이디와 비밀번호를
          같게 만들어 줍니다.
        </Box>
        <Box style={EnterpriseTextFieldBox1}>
          <Box
            style={{
              whiteSpace: 'nowrap',
              fontSize: '2rem',
              marginRight: '2rem',
              width: '10rem',
              color: '#516561',
              fontWeight: 'bold',
            }}
          >
            아이디
          </Box>
          <Box style={{width: '100%', maxWidth: '30rem'}}>
            <TextField
              autoComplete="off"
              variant="outlined"
              fullWidth
              sx={{
                maxWidth: '30rem',
                backgroundColor: 'white',
                borderRadius: '0.3rem',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '2px solid #00c500',
                  },
                  '&:hover fieldset': {
                    border: '2px solid #00c500',
                  },
                  '&.Mui-focused fieldset': {
                    border: '2px solid #00c500',
                  },
                },
              }}
              label={idText == '' ? '아이디를 입력해주세요.' : ''}
              InputLabelProps={{
                style: {
                  color: '#879390',
                  fontSize: 'calc(0.5rem + 0.7vw)',
                },
                shrink: false,
              }}
              InputProps={{style: {fontSize: 'calc(0.5rem + 0.7vw)'}}}
              value={idText}
              onChange={handleIdText}
            ></TextField>
            {showIDError()}
          </Box>
        </Box>
        <Box style={EnterpriseTextFieldBox2}>
          <Box
            style={{
              whiteSpace: 'nowrap',
              fontSize: '2rem',
              marginRight: '2rem',
              width: '10rem',
              color: '#516561',
              fontWeight: 'bold',
            }}
          >
            비밀번호
          </Box>
          <Box style={{width: '100%', maxWidth: '30rem'}}>
            <TextField
              autoComplete="off"
              variant="outlined"
              type="password"
              fullWidth
              onKeyDown={onKeyPressed}
              sx={{
                maxWidth: '30rem',
                backgroundColor: 'white',
                borderRadius: '0.3rem',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '2px solid #00c500',
                  },
                  '&:hover fieldset': {
                    border: '2px solid #00c500',
                  },
                  '&.Mui-focused fieldset': {
                    border: '2px solid #00c500',
                  },
                },
              }}
              label={pwText == '' ? '(영어, 숫자 조합 5글자 이상)' : ''}
              InputLabelProps={{
                style: {
                  color: '#879390',
                  fontSize: 'calc(0.5rem + 0.7vw)',
                },
                shrink: false,
              }}
              InputProps={{style: {fontSize: 'calc(0.5rem + 0.7vw)'}}}
              value={pwText}
              onChange={handlePwText}
            ></TextField>
            {showPWError()}
          </Box>
        </Box>

        <Box style={EnterpriseEndBox}>
          <Button
            style={{
              color: 'white',
              backgroundColor: '#00c500',
              paddingLeft: '4rem',
              paddingRight: '4rem',
              fontSize: '1.2rem',
              borderRadius: '0.7rem',
            }}
            onClick={() => {
              callEnterpriseLoginAPI();
            }}
          >
            로그인
          </Button>
        </Box>
        <Box style={EnterpriseNoticeBox}>
          <Box>
            <Box>※아이디/비밀번호는 관리자에게 문의하세요.</Box>
            <Box>※문의 : support@twoblockai.com</Box>
          </Box>
        </Box>
      </Box>
      <FooterView />
    </Box>
  );
};
export default LoginPage;
