import {Box, Button, tabsClasses} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import APIUtils from 'common/utils/APIUtils';
import TeacherView2 from 'view/mypage/MobileTeacherView2';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  mypageBox1,
  mypageBox2,
  mypageWholePage,
} from 'view/mypage/MobileStyles';
import AuthTeacherPopup from 'view/popupMobile/AuthTeacherPopup';
import AuthTeacherCompletePopup from 'view/popupMobile/AuthTeacherCompletePopup';

export const FragmentView = props => {
  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);

  const [teacherList, setTeacherList] = useState([]);
  const [completeVisibility, setCompleteVisibility] = useState(false);
  function completeCancel() {
    setCompleteVisibility(false);
  }

  const [authTeacherVisibility, setAuthTeacherVisibility] = useState(false);
  function authTeacherCancel() {
    setAuthTeacherVisibility(false);
  }
  function authTeacherConfirm() {
    setAuthTeacherVisibility(false);
    setCompleteVisibility(true);
  }
  function getTeacherList() {
    const getTeacher = async () => {
      try {
        const response = await APIUtils.TaskTeacherList(authReducer.student_id);
        if (response.status == 200 && response.data.ret_code == 1000) {
          setTeacherList(response.data.teacher_list);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getTeacher();
  }
  useEffect(() => {
    getTeacherList();
    // getAuthCount();
  }, []);
  return (
    <Box
      style={{
        width: '100%',
      }}
    >
      <AuthTeacherCompletePopup
        visibility={completeVisibility}
        cancelEvent={completeCancel}
      />
      <AuthTeacherPopup
        visibility={authTeacherVisibility}
        cancelEvent={authTeacherCancel}
        confirmEvent={authTeacherConfirm}
      />
      <Box style={mypageBox1}>
        <Box
          style={{
            fontSize: '1.7rem',
            fontWeight: 'bold',
            marginTop: '0.5rem',
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          {authReducer.student_name}
          {' 님'}
          <Box
            style={{color: '#68737d', fontSize: '0.8rem', fontWeight: 'normal'}}
          >
            {authReducer.student_email}
          </Box>
        </Box>
        <Box
          style={{
            marginTop: '1rem',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            style={{
              border: '1px solid #00c500',
              borderRadius: '0.5rem',
              backgroundColor: 'white',
              color: '#00c500',
              width: '100%',
            }}
            onClick={() => {
              setAuthTeacherVisibility(true);
            }}
          >
            선생님 연결하기
          </Button>
        </Box>
      </Box>
      <Box
        style={{
          fontWeight: 'bold',
          fontSize: '1.375rem',
          marginTop: '2rem',
          marginLeft: '1rem',
          color: '#717171',
        }}
      >
        나의 선생님
      </Box>

      <Box style={{marginTop: '1rem', marginBottom: '2rem', minHeight: '8rem'}}>
        <Box
          style={{
            display: teacherList.length == 0 ? '' : 'none',
            fontSize: '0.825rem',
            marginLeft: '1rem',
          }}
        >
          연결 중인 선생님이 없습니다.
        </Box>
        {teacherList.map((val, idx) => {
          return (
            <TeacherView2
              key={'teacherVeiw' + idx}
              teacher_info={val}
              selectEvent={props.classSelect}
            />
          );
        })}
      </Box>
    </Box>
  );
};
FragmentView.propTypes = {
  classSelect: PropTypes.func,
};
export default FragmentView;
