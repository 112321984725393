import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import React, {useState} from 'react';

export const TeacherView = props => {
  return (
    <Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '1.5rem',
          paddingBottom: '0.7rem',
          borderBottom: '2px solid #A8B1B7',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Box
            style={{
              visibility: props.teacher_info.new_task ? 'visible' : 'hidden',
              backgroundColor: '#00C500',
              width: '1rem',
              height: '1rem',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              borderRadius: '1.5rem',
              marginRight: '0.2rem',
              fontWeight: 'bold',
              fontSize: '0.75rem',
              textAlign: 'center',
            }}
          >
            N
          </Box>
          <Box
            style={{
              marginLeft: '0.5rem',
              fontSize: '1.125rem',
              fontWeight: 'bold',
            }}
          >
            {props.teacher_info.teacher_name + ' 선생님'}
          </Box>
        </Box>
        <Button
          style={{
            color: '#00C500',
            fontSize: '0.875rem',
            border: '1px solid #00C500',
            borderRadius: '1.625rem',
            padding: '0.7rem 0rem',
            width: '7rem',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
          onClick={() =>
            props.selectEvent(
              props.teacher_info.user_id,
              props.teacher_info.teacher_name,
            )
          }
        >
          {'과제 보기'}
        </Button>
      </Box>
    </Box>
  );
};
TeacherView.propTypes = {
  teacher_info: PropTypes.object,
  selectEvent: PropTypes.func,
};
export default TeacherView;
