import {Box, Button} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import APIUtils from 'common/utils/APIUtils';
import PropTypes from 'prop-types';
import TaskView1 from './TaskView1';
import Pagination from 'view/mypage/Pagination';
import ApiLoading from 'view/common/ApiLoading2';

export const FragmentView = props => {
  const authReducer = useSelector(state => state.authReducer);
  const [tabStatus, setTabStatus] = useState(1);
  const [loading, setLoading] = useState(true);

  const [tab1Count, setTab1Count] = useState(0);
  const [tab2Count, setTab2Count] = useState(0);
  const [tab3Count, setTab3Count] = useState(0);
  const [originalTaskList, setOriginalTaskList] = useState([]);
  const [filteredTaskList, setFilteredTaskList] = useState([]);

  const countPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  function paginationClickEvent(page) {
    setCurrentPage(page);
  }

  function getTaskList() {
    const taskList = async () => {
      try {
        const response = await APIUtils.TaskList(
          authReducer.student_id,
          props.user_id,
        );
        if (response.status == 200 && response.data.ret_code == 1000) {
          setOriginalTaskList(response.data.task_list);
          setTab1Count(response.data.task_list.length);
          setTab2Count(
            response.data.task_list.filter(
              val =>
                val.task_status == '진행중' || val.task_status === '최근 종료',
            ).length,
          );
          setTab3Count(
            response.data.task_list.filter(val => val.task_status == '종료')
              .length,
          );
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    taskList();
  }
  function getFilteredTask() {
    if (originalTaskList == undefined || originalTaskList.length == 0) {
      setFilteredTaskList([]);
      return;
    }
    let filtered = [...originalTaskList];
    if (tabStatus == 2) {
      filtered = filtered.filter(
        item =>
          item.task_status == '진행중' || item.task_status === '최근 종료',
      );
    } else if (tabStatus == 3) {
      filtered = filtered.filter(item => item.task_status == '종료');
    }
    filtered.sort((a, b) => {
      if (a.task_status == '진행중' && b.task_status != '진행중') return -1;
      if (a.task_status != '진행중' && b.task_status == '진행중') return 1;
      if (a.task_status == '대기' && b.task_status != '대기') return -1;
      if (a.task_status != '대기' && b.task_status == '대기') return 1;
      return new Date(b.task_end_time) - new Date(a.task_end_time);
    });
    setFilteredTaskList(filtered);
    setTotalPage(Math.floor((filtered.length - 1) / countPerPage + 1));
  }
  useEffect(() => {
    getTaskList();
  }, []);
  useEffect(() => {
    getFilteredTask();
  }, [originalTaskList, tabStatus]);
  if (loading) {
    return <ApiLoading />;
  }
  return (
    <Box>
      <Box style={{fontWeight: 'bold', fontSize: '1.375rem'}}>
        {props.teacher_name + ' 선생님'}
      </Box>
      <Box
        style={{
          backgroundColor: '#E6E8EB',
          width: '100%',
          height: '2px',
          marginTop: '1.3rem',
          marginBottom: '1.75rem',
        }}
      />
      <Box style={{color: '#87929D', fontSize: '0.875rem'}}>
        과제의 기간이 종료되어도 선생님이 평가를 공유하기 전에는{' '}
        <font color="red">늦은 제출</font>이 가능합니다.{' '}
        <font color="#279EFF">평가 리포트</font>는 PDF 파일입니다.
      </Box>
      <Box style={{display: 'flex', marginTop: '2rem'}}>
        <Button
          style={{
            color: tabStatus == 1 ? 'black' : '#ADB4BA',
            borderBottom:
              tabStatus == 1 ? '1px solid black' : '1px solid #F4F6F6',
            borderRadius: 0,
            padding: '0.5rem 2rem',
            whiteSpace: 'nowrap',
            fontSize: '0.875rem',
          }}
          onClick={() => {
            setTabStatus(1);
          }}
        >
          전체
          <span
            style={{
              marginLeft: '0.5rem',
              color: tabStatus == 1 ? '#00C500' : '',
            }}
          >
            {tab1Count}
          </span>
        </Button>
        <Button
          style={{
            color: tabStatus == 2 ? 'black' : '#ADB4BA',
            borderBottom:
              tabStatus == 2 ? '1px solid black' : '1px solid #F4F6F6',
            borderRadius: 0,
            padding: '0.5rem 2rem',
            whiteSpace: 'nowrap',
            fontSize: '0.875rem',
          }}
          onClick={() => {
            setTabStatus(2);
          }}
        >
          진행중
          <span
            style={{
              marginLeft: '0.5rem',
              color: tabStatus == 2 ? '#00C500' : '',
            }}
          >
            {tab2Count}
          </span>
        </Button>
        <Button
          style={{
            color: tabStatus == 3 ? 'black' : '#ADB4BA',
            borderBottom:
              tabStatus == 3 ? '1px solid black' : '1px solid #F4F6F6',
            borderRadius: 0,
            padding: '0.5rem 2rem',
            whiteSpace: 'nowrap',
            fontSize: '0.875rem',
          }}
          onClick={() => {
            setTabStatus(3);
          }}
        >
          종료
          <span
            style={{
              marginLeft: '0.5rem',
              color: tabStatus == 3 ? '#00C500' : '',
            }}
          >
            {tab3Count}
          </span>
        </Button>
      </Box>
      <Box style={{marginBottom: '2rem'}}>
        {filteredTaskList
          .filter((value, index) => {
            if (Math.floor(index / countPerPage) + 1 == currentPage) {
              return true;
            }
            return false;
          })
          .map((val, idx) => {
            return <TaskView1 key={'taskview' + idx} task={val} />;
          })}
      </Box>
      <Pagination
        totalPage={totalPage}
        currentPage={currentPage}
        clickEvent={paginationClickEvent}
      />
    </Box>
  );
};
FragmentView.propTypes = {
  user_id: PropTypes.number,
  teacher_name: PropTypes.string,
};
export default FragmentView;
