import React, {useEffect, useState} from 'react';
import {Box, Button, IconButton, TextField} from '@mui/material';
import APIUtils from 'common/utils/APIUtils';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

const ItemView = props => {
  const authReducer = useSelector(state => state.authReducer);
  const [messageText, setMessageText] = useState('');
  const [teacherInfo, setTeacherInfo] = useState('');
  const handleMessage = event => {
    let inputVal = event.target.value;
    setMessageText(inputVal);
  };
  const [emailText, setEmailText] = useState('');
  const [emailState, setEmailState] = useState(0);
  const handleEmail = event => {
    let inputVal = event.target.value;
    if (inputVal.length > 50) {
      inputVal = inputVal.substring(0, 50);
    }
    setEmailText(inputVal);
    setEmailState(0);
  };
  function validateTeacherEmail() {
    const validateResult = async inputEmail => {
      try {
        const response = await APIUtils.TeacherAuthCheck(
          authReducer.student_id,
          inputEmail,
        );
        if (response.data.ret_code == 1000) {
          setEmailState(1);
          if (response.data.group_name == '') {
            setTeacherInfo(response.data.teacher_name + ' 선생님');
          } else {
            setTeacherInfo(
              response.data.teacher_name +
                ' 선생님 (' +
                response.data.group_name +
                ')',
            );
          }
        } else {
          // UNKNOWN_GROUP: 2501,
          setEmailState(2);
        }
      } catch (err) {
        console.log(err);
      }
    };
    validateResult(emailText);
  }
  useEffect(() => {
    setEmailText('');
    setEmailState(0);
    setMessageText('');
  }, []);
  function requestTeacher() {
    const teacherAuth = async (inputEmail, inputMemo) => {
      try {
        const res = await APIUtils.TeacherAuth(
          authReducer.student_id,
          inputEmail,
          inputMemo,
        );
        return res;
      } catch (err) {
        console.log(err);
      }
    };

    teacherAuth(emailText, messageText).then(r => {
      props.confirmEvent();
    });
  }
  function showValidateResult() {
    if (emailState == 2) {
      return (
        <Box style={{marginTop: '0.5rem', color: 'red'}}>
          <Box>
            {'이메일 주소가 없습니다. 선생님에게 이메일 주소를 문의하세요.'}
          </Box>
        </Box>
      );
    }
    if (emailState == 1) {
      return (
        <Box
          style={{marginTop: '0.5rem', color: '#00C500', fontWeight: 'bold'}}
        >
          <Box>{teacherInfo}</Box>
        </Box>
      );
    }
  }

  return (
    <Box>
      <Box style={{marginTop: '1.5rem'}}>
        선생님 이메일<span style={{color: '#00C500'}}>*</span>
      </Box>
      <Box style={{display: 'flex', alignItems: 'center', marginTop: '1rem'}}>
        <TextField
          autoComplete="off"
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '1px solid #EBEEF4',
                borderRadius: '0.625rem',
              },
              '&:hover fieldset': {
                border: '1px solid #EBEEF4',
                borderRadius: '0.625rem',
              },
              '&.Mui-focused fieldset': {
                border: '1px solid #EBEEF4',
                borderRadius: '0.625rem',
              },
            },
          }}
          variant="outlined"
          fullWidth
          label={emailText == '' ? '이메일 형식' : ''}
          InputLabelProps={{
            style: {
              color: '#A8B1B7',
            },
            shrink: false,
          }}
          InputProps={{
            style: {
              backgroundColor: '#F7F8FA',
              fontWeight: 500,
            },
          }}
          value={emailText}
          onChange={handleEmail}
        ></TextField>
        <Button
          style={{
            whiteSpace: 'nowrap',
            color: 'white',
            backgroundColor: '#00C500',
            fontSize: '1.125rem',
            fontWeight: 'bold',
            padding: '0.9rem 3.5rem',
            marginLeft: '1rem',
            borderRadius: '6.25rem',
            width: '7rem',
          }}
          onClick={() => validateTeacherEmail()}
        >
          계정 확인
        </Button>
      </Box>
      {showValidateResult()}
      <Box style={{marginTop: '1.5rem'}}>인증 요청 메세지</Box>
      <Box style={{marginTop: '1rem', maxHeight: '15rem', overflowY: 'auto'}}>
        <TextField
          autoComplete="off"
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '1px solid #EBEEF4',
                borderRadius: '0.625rem',
              },
              '&:hover fieldset': {
                border: '1px solid #EBEEF4',
                borderRadius: '0.625rem',
              },
              '&.Mui-focused fieldset': {
                border: '1px solid #EBEEF4',
                borderRadius: '0.625rem',
              },
            },
          }}
          variant="outlined"
          fullWidth
          label={
            messageText == ''
              ? '자신을 알릴 수 있는 메세지를 적어 주세요. (예 - 학년 반 이름)'
              : ''
          }
          InputLabelProps={{
            style: {
              color: '#A8B1B7',
            },
            shrink: false,
          }}
          InputProps={{
            style: {
              backgroundColor: '#F7F8FA',
              fontWeight: 500,
            },
          }}
          multiline={true}
          value={messageText}
          onChange={handleMessage}
        ></TextField>
      </Box>
      <Box style={{marginTop: '3rem'}}>
        <Button
          disabled={emailState != 1}
          style={{
            width: '100%',
            whiteSpace: 'nowrap',
            color: 'white',
            backgroundColor: emailState != 1 ? '#A8B1B7' : '#00C500',
            fontSize: '1.125rem',
            fontWeight: 'bold',
            padding: '0.9rem 0rem',
            marginLeft: '1rem',
            borderRadius: '6.25rem',
          }}
          onClick={() => requestTeacher()}
        >
          인증 요청하기
        </Button>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  confirmEvent: PropTypes.func,
  cancelEvent: PropTypes.func,
};
export default ItemView;
