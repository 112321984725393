import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import KeewiChip from 'view/result/KeewiChip';
import {CommentBox} from 'view/common/Components';

const ItemView = props => {
  return (
    <CommentBox>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
          textDecorationColor: 'black',
          textDecorationThickness: '1px',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <Box>
            <KeewiChip subtype={props.subtype} />
          </Box>
          <Box
            style={{
              marginLeft: '0.5rem',
              flex: 1,
              wordBreak: 'break-all',
              fontSize: '1rem',
              fontWeight: '500',
              color: 'rgba(48, 48, 48, 1)',
              whiteSpace: 'pre-wrap',
            }}
          >
            {props.commentID == -1 ? '' : '(' + props.commentID + ') '}
            <span
              style={{
                marginLeft: '0.5rem',
                whiteSpace: 'pre-wrap', // 줄바꿈 및 공백 보존
              }}
            >
              {props.C}
            </span>
          </Box>
        </Box>
      </Box>
    </CommentBox>
  );
};
ItemView.propTypes = {
  paragraphIndex: PropTypes.number,
  idx: PropTypes.number,
  commentID: PropTypes.number,
  C: PropTypes.string,
  subtype: PropTypes.string,
};
export default ItemView;
