import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import HeaderView from 'view/common/Header';
import FooterView from 'view/common/Footer';
import APIUtils from 'common/utils/APIUtils';
import {NoticeItem} from 'view/notice/NoticeItem';
import Pagination from 'view/mypage/Pagination';

const NoticePage = () => {
  const [serverNotice, setServerNotice] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const countPerPage = 15;
  function clickEvent(page) {
    setCurrentPage(page);
  }

  function setNoticeList(noticeList) {
    setServerNotice(noticeList);
  }
  useEffect(() => {
    const getNoticeList = async () => {
      try {
        const response = await APIUtils.NoticeListKeewi();
        return response;
      } catch (err) {
        console.log(err);
        return 0;
      }
    };

    getNoticeList().then(r => {
      const filteredList = r.data.notice_list.filter(notice =>
        notice.title.includes('@키위런@'),
      );
      setNoticeList(filteredList);
      setTotalPage(Math.floor((filteredList.length - 1) / countPerPage + 1));
    });
  }, []);
  return (
    <Box
      style={{
        backgroundColor: 'white',
        minHeight: '100%',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <HeaderView />
      <Box
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          justifyItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box
          style={{
            maxWidth: '70rem',
            textAlign: 'start',
            width: '80%',
            fontSize: 'calc(2rem + 0.5vw)',
            fontWeight: 'bold',
            color: '#717171',
            marginTop: '5rem',
            marginBottom: '1rem',
          }}
        >
          공지사항
        </Box>
        <Box
          style={{
            backgroundColor: '#f3f3f3',
            height: '3px',
            width: '70%',
            marginTop: '3rem',
            marginBottom: '3rem',
          }}
        />
        <Box
          style={{
            maxWidth: '70rem',
            width: '80%',
            minHeight: '50rem',
            marginBottom: '5rem',
          }}
        >
          {serverNotice
            .filter((value, index) => {
              if (Math.floor(index / countPerPage) + 1 == currentPage) {
                return true;
              }
              return false;
            })
            .map((value, index) => {
              return (
                <NoticeItem
                  key={'noticeItem' + index}
                  title={value.title
                    .replace(/@키위런@|@키위티@/g, '')
                    .trimStart()}
                  content={value.content}
                  time={value.notice_time}
                />
              );
            })}
        </Box>
        <Box
          style={{
            marginTop: '2rem',
            fontWeight: 'bold',
            fontSize: 'calc(0.8rem + 0.5vw)',
          }}
        >
          <Pagination
            totalPage={totalPage}
            currentPage={currentPage}
            clickEvent={clickEvent}
          />
        </Box>
      </Box>
      <FooterView />
    </Box>
  );
};
export default NoticePage;
