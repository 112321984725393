import React from 'react';
import {Box, Button} from '@mui/material';

import logoTBAI from 'asset/image/logo_tbai.png';
import {footerBox1} from 'view/login/Styles';
import {useDispatch, useSelector} from 'react-redux';

const FooterView = () => {
  const authReducer = useSelector(state => state.authReducer);
  const isSoluny = authReducer.student_email.startsWith('soluny');

  return (
    <Box style={footerBox1}>
      <Box>
        <Box>
          <Box style={{display: 'flex'}}>
            <a
              href="https://twoblockai.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={logoTBAI}
                alt={'logoT'}
                style={{height: '60px', cursor: 'pointer'}}
              />
            </a>
          </Box>
          <Box
            ml={2}
            style={{
              fontSize: '0.8rem',
              fontWeight: '500',
              color: '#68737D',
            }}
          >
            <Box style={{marginTop: '1.5rem'}}>
              상호: 투블럭에이아이, 대표자: 조영환
            </Box>
            <Box style={{marginTop: '0.5rem'}}>
              주소: 서울시 서초구 남부순환로 350길 54 V-TOWER 1층{' '}
            </Box>
            <Box style={{marginTop: '0.5rem'}}>
              사업자등록번호: 316-88-01580
            </Box>
            <Box style={{marginTop: '0.5rem'}}>
              통신판매업신고: 2020-서울서초-4283&nbsp;&nbsp;&nbsp;&nbsp;Email:
              support@twoblockai.com
            </Box>
            <Box style={{marginTop: '0.5rem', color: '#A8b1b7'}}>
              Copyright (C) 2023 TwoBlockAi All Rights Reserved.
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'end',
          alignItems: 'start',
        }}
      >
        <Box
          style={{
            display: isSoluny ? 'none' : '',
            color: '#2F3941',
            fontSize: 'calc(1.0rem + 0.8vw)',
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
          }}
        >
          문의 전화
        </Box>
        <Box
          style={{
            display: isSoluny ? '' : 'none',
            color: '#2F3941',
            fontSize: 'calc(1.0rem + 0.8vw)',
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
          }}
        >
          문의 전화
        </Box>
        <Box
          style={{
            display: isSoluny ? 'none' : '',
            color: '#00C500',
            fontSize: 'calc(0.8rem + 0.8vw)',
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            marginTop: '0.8rem',
          }}
        >
          02-6677-0222
        </Box>
        <Box
          style={{
            display: isSoluny ? '' : 'none',
            color: '#00C500',
            fontSize: '1.375rem',
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            marginTop: '0.8rem',
          }}
        >
          02-6677-0222
        </Box>
        <Box
          style={{
            color: '#68737D',
            fontSize: '1rem',
            whiteSpace: 'nowrap',
            marginTop: '0.5rem',
          }}
        >
          평일 10:00 - 17:00
        </Box>
        <Box
          style={{
            color: '#68737D',
            fontSize: '1rem',
            whiteSpace: 'nowrap',
            marginTop: '0.3rem',
          }}
        >
          주말, 공휴일 휴무
        </Box>
      </Box>
    </Box>
  );
};
export default FooterView;
