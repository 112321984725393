import axios from 'axios';
import AESUtils from './AES256Utils';

const APIUtils = {
  async VesionCheck() {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const result = await axios.post('/keewi/version/check', {}, config);
    return result;
  },
  async AccountLogin(access_path, access_token) {
    const encrypted = AESUtils.encodeByAES56({
      access_path: access_path,
      access_token: access_token,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/account/login',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async AccountLoginSchool(login_id, login_pw) {
    const encrypted = AESUtils.encodeByAES56({
      login_id: login_id,
      login_pw: login_pw,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/account/login/school',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    return response;
  },
  async AccountLeave(student_id) {
    const encrypted = AESUtils.encodeByAES56({
      student_id: student_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/account/leave',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async AccountPassCheck(student_id) {
    const encrypted = AESUtils.encodeByAES56({
      student_id: student_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/account/pass/check',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async AccountModify(student_id, name) {
    const encrypted = AESUtils.encodeByAES56({
      student_id: student_id,
      name: name,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/account/modify',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async AccountPasswordChange(student_id, current_pw, new_pw) {
    const encrypted = AESUtils.encodeByAES56({
      student_id: student_id,
      current_pw: current_pw,
      new_pw: new_pw,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/account/password/change',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async TeacherList(student_id) {
    const encrypted = AESUtils.encodeByAES56({
      student_id: student_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/teacher/list',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async CancelTaskSubmit(student_id, task_id) {
    console.log(
      'CancelTaskSubmit : student_id = ' +
        student_id +
        ', task_id : ' +
        task_id,
    );
    const encrypted = AESUtils.encodeByAES56({
      student_id: student_id,
      task_id: task_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/task/cancle/submit',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    return response;
  },
  async TeacherAuthCheck(student_id, email) {
    const encrypted = AESUtils.encodeByAES56({
      student_id: student_id,
      email: email,
      auth_key: '',
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/teacher/auth/check',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    return response;
  },
  async TeacherAuthCheckWithCode(student_id, auth_key) {
    const encrypted = AESUtils.encodeByAES56({
      student_id: student_id,
      email: '',
      auth_key: auth_key,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/teacher/auth/check',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    return response;
  },
  async TeacherAuth(student_id, email, auth_memo) {
    const encrypted = AESUtils.encodeByAES56({
      student_id: student_id,
      email: email,
      auth_key: '',
      auth_memo: auth_memo,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/teacher/auth',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async TeacherAuthWithCode(student_id, auth_key, auth_memo) {
    const encrypted = AESUtils.encodeByAES56({
      student_id: student_id,
      email: '',
      auth_key: auth_key,
      auth_memo: auth_memo,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/teacher/auth',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async TaskTeacherList(student_id) {
    const encrypted = AESUtils.encodeByAES56({
      student_id: student_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/task/teacher/list',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async TaskList(student_id, user_id) {
    const encrypted = AESUtils.encodeByAES56({
      student_id: student_id,
      user_id: user_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/task/list',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async TaskCall(student_id, task_id) {
    const encrypted = AESUtils.encodeByAES56({
      student_id: student_id,
      task_id: task_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/task/call',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async TaskEval(student_id, task_id, title, janre, text) {
    const encrypted = AESUtils.encodeByAES56({
      student_id: student_id,
      task_id: task_id,
      title: title,
      janre: janre,
      text: text,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/task/eval',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async TaskEvalSave(student_id, task_id, eval_result) {
    const encrypted = AESUtils.encodeByAES56({
      student_id: student_id,
      task_id: task_id,
      eval_result: eval_result,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/task/eval/save',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async TaskSubmit(student_id, task_id) {
    const encrypted = AESUtils.encodeByAES56({
      student_id: student_id,
      task_id: task_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/task/submit',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async TaskResult(student_id, task_id, eval_try) {
    let encrypted;
    if (eval_try == undefined) {
      encrypted = AESUtils.encodeByAES56({
        student_id: student_id,
        task_id: task_id,
      });
    } else {
      encrypted = AESUtils.encodeByAES56({
        student_id: student_id,
        task_id: task_id,
        eval_try: eval_try,
      });
    }
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/task/result',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async TaskOngoing(student_id) {
    const encrypted = AESUtils.encodeByAES56({
      student_id: student_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/task/ongoing',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async PracticeEval(student_id, title, janre, text) {
    const encrypted = AESUtils.encodeByAES56({
      student_id: student_id,
      title: title,
      janre: janre,
      text: text,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/practice/eval',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async PracticeEvalSave(student_id, eval_result) {
    const encrypted = AESUtils.encodeByAES56({
      student_id: student_id,
      eval_result: eval_result,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/practice/eval/save',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async PracticeModifySave(student_id, practice_eval_id, eval_result) {
    const encrypted = AESUtils.encodeByAES56({
      student_id: student_id,
      practice_eval_id: practice_eval_id,
      eval_result: eval_result,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/practice/modify/save',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async PracticeEvalKeewichat(student_id, practice_eval_id) {
    const encrypted = AESUtils.encodeByAES56({
      student_id: student_id,
      practice_eval_id: practice_eval_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/practice/eval/keewichat',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async ChatLogs(task_id, student_id) {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const response = await axios.post(
      '/keewi/chat/logs',
      {
        task_id: task_id,
        student_id: student_id,
      },
      config,
    );
    return response;
  },
  async ChatInitialize(
    task_id,
    student_id,
    task_name,
    task_message,
    task_janre,
    keyword_check,
    keyword,
    length_check,
    min_length,
    max_length,
  ) {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const response = await axios.post(
      '/keewi/chat/initialize',
      {
        task_id: task_id,
        student_id: student_id,
        task_name: task_name,
        task_message: task_message,
        task_janre: task_janre,
        keyword_check: keyword_check,
        keyword: keyword,
        length_check: length_check,
        min_length: min_length,
        max_length: max_length,
      },
      config,
    );
    return response;
  },
  async ChatDialogue(
    task_id,
    student_id,
    message,
    task_name,
    task_message,
    task_janre,
    keyword_check,
    keyword,
    length_check,
    min_length,
    max_length,
  ) {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const response = await axios.post(
      '/keewi/chat/dialogue',
      {
        task_id: task_id,
        student_id: student_id,
        message: message,
        task_name: task_name,
        task_message: task_message,
        task_janre: task_janre,
        keyword_check: keyword_check,
        keyword: keyword,
        length_check: length_check,
        min_length: min_length,
        max_length: max_length,
      },
      config,
    );
    return response;
  },
  async CheckAnnouncement() {
    const response = await axios.get('/keewi/check/announcement');
    return response;
  },
  async TaskHistoryView(student_id) {
    const encrypted = AESUtils.encodeByAES56({
      student_id: student_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/task/history/view',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async TaskHistorySubmitResult(student_id, task_id) {
    const encrypted = AESUtils.encodeByAES56({
      student_id: student_id,
      task_id: task_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/task/history/submit/result',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async TaskHistoryEvalResult(student_id, task_id, eval_try) {
    const encrypted = AESUtils.encodeByAES56({
      student_id: student_id,
      task_id: task_id,
      eval_try: eval_try,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/task/history/eval/result',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async PracticeHistoryView(student_id) {
    const encrypted = AESUtils.encodeByAES56({
      student_id: student_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/practice/history/view',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async PracticeHistoryEvalResult(student_id, practice_eval_id, eval_try) {
    const encrypted = AESUtils.encodeByAES56({
      student_id: student_id,
      practice_eval_id: practice_eval_id,
      eval_try: eval_try,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/practice/history/eval/result',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async PracticeHistoryHide(student_id, practice_eval_id) {
    const encrypted = AESUtils.encodeByAES56({
      student_id: student_id,
      practice_eval_id: practice_eval_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/practice/history/hide',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async LoggerLog(user_id, tag, level, from, event) {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const logging = async () => {
      try {
        const response = await axios.post(
          '/keewi/logger/log',
          {user_id: user_id, tag: tag, level: level, from: from, event: event},
          config,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    logging();
  },
  alertError: function (response) {
    if (response.status != 200) {
      alert('인터넷 연결을 확인해 주세요.');
    } else if (response.data.ret_code == 1000) {
      //success do nothing
    } else if (response.data.ret_code == 9999) {
      alert('분석이 원활하지 않습니다. 잠시후 평가하기를 눌러 주세요.'); // 엔진에러
    } else if (response.data.ret_code == 9998) {
      alert('입력한 값을 다시 확인해 주세요.'); // 입력 오류
    } else if (response.data.ret_code == 9997) {
      alert('데이터 저장에 실패하였습니다. '); // DB 오류
    } else if (response.data.ret_code == 9996) {
      alert('서비스가 원할하지 않습니다. 조금 후에 다시 시도해 주세요.'); // API 오류
    } else if (response.data.ret_code == 9995) {
      alert('1분후에 다시 시도해 주세요.');
    } else if (response.data.ret_code == 2001) {
      alert('존재하지 않는 사용자입니다.');
    } else if (response.data.ret_code == 2002) {
      alert('유효하지 않은 사용자 정보입니다.');
    } else if (response.data.ret_code == 2003) {
      alert('이미 탈퇴한 사용자입니다.');
    } else if (response.data.ret_code == 2101) {
      alert('pg사 승인 실패');
    } else if (response.data.ret_code == 2102) {
      alert('이미 결제된 아이템입니다.');
    } else if (response.data.ret_code == 2103) {
      alert('정상적이지 않은 결제 정보입니다.');
    } else if (response.data.ret_code == 2104) {
      // done?
    } else if (response.data.ret_code == 2201) {
      alert('존재하지 않는 아이템');
    } else if (response.data.ret_code == 2202) {
      alert('유효하지 않은 보관함 ID');
    } else if (response.data.ret_code == 2203) {
      alert('이용권 개수 부족');
    } else if (response.data.ret_code == 2301) {
      alert('존재하지 않는 데이터');
    }
    //2401~2405는 code popup에서 처리
  },
  async NoticeListKeewi() {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post('/keewi/notice/list', {}, config);
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
};
export default APIUtils;
