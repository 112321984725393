import React, {useState, useEffect} from 'react';
import {Box, Button, TextField} from '@mui/material';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
const ItemView = props => {
  if (!props.visibility) {
    return <></>;
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        backgroundColor: 'rgba(55,55,55,0.5)',
      }}
    >
      <Box
        style={{
          width: '28rem',
          textAlign: 'left',
          borderRadius: '20px',
          background: 'white',
          paddingLeft: '3rem',
          paddingRight: '3rem',
          paddingBottom: '2rem',
          paddingTop: '1rem',
          color: 'black',
        }}
      >
        <Box
          style={{
            fontSize: '1.7rem',
            fontWeight: 'bold',
            marginBottom: '1rem',
            marginTop: '0.5rem',
          }}
        >
          과제 제출을 취소하시겠습니까?
        </Box>
        <Box style={{fontSize: '1.1rem'}}>수정 후에 다시 제출해야 합니다.</Box>

        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '3rem',
          }}
        >
          <Button
            style={{
              border: '1px solid black',
              width: '47%',
              color: 'black',
              fontSize: 'calc(1rem + 0.2vw)',
              fontWeight: 'bold',
              borderRadius: '10px',
              paddingTop: '0.7rem',
              paddingBottom: '0.7rem',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
            onClick={() => {
              props.cancelEvent();
            }}
          >
            제출 유지하기
          </Button>
          <Button
            style={{
              backgroundColor: '#FF9900',
              width: '47%',
              color: 'white',
              fontSize: 'calc(1rem + 0.2vw)',
              fontWeight: 'bold',
              borderRadius: '10px',
              paddingTop: '0.7rem',
              paddingBottom: '0.7rem',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
            onClick={() => {
              props.submitReset();
            }}
          >
            제출 취소하기
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  visibility: PropTypes.bool,
  submitReset: PropTypes.func,
  cancelEvent: PropTypes.func,
};
export default ItemView;
