import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import {StringUtils} from 'common/utils/StringUtils';
import React, {useState, useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import APIUtils from 'common/utils/APIUtils';
import {useSelector} from 'react-redux';

export const TaskView = props => {
  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);
  const dateLeft = StringUtils.getDateDiff(props.task.task_end_time);
  const timeLeft = StringUtils.getTimeDiff(props.task.task_end_time);

  function showLabel() {
    if (timeLeft >= 0) {
      props.task.task_status = '진행중';
    } else {
      props.task.task_status = '종료';
    }
    if (props.task.task_status == '진행중') {
      return (
        <Box style={{display: 'flex', gap: '0.5rem'}}>
          <Box
            style={{
              color: 'white',
              backgroundColor: '#68737D',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
            }}
          >
            D-{dateLeft}
          </Box>
          <Box
            style={{
              color: '#FF9900',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
              border: '1px solid #D8DCDE',
            }}
          >
            진행중
          </Box>
          <Box
            style={{
              display: props.task.task_type == 1 ? '' : 'none',
              color: '#279EFF',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
              border: '1px solid #279EFF',
            }}
          >
            {'AI 피드백'}
          </Box>
        </Box>
      );
    } else if (props.task.task_status == '대기') {
      return (
        <Box style={{display: 'flex', gap: '0.5rem'}}>
          <Box
            style={{
              color: '#68737D',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
              border: '1px solid #68737D',
            }}
          >
            대기
          </Box>
          <Box
            style={{
              display: props.task.task_type == 1 ? '' : 'none',
              color: '#279EFF',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
              border: '1px solid #279EFF',
            }}
          >
            {'AI 피드백'}
          </Box>
        </Box>
      );
    } else if (props.task.task_status == '종료') {
      return (
        <Box style={{display: 'flex', gap: '0.5rem'}}>
          <Box
            style={{
              color: '#87929D',
              backgroundColor: '#E9EBED',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
            }}
          >
            {timeLeft < 0 ? `D+${Math.abs(dateLeft)}` : `D-${dateLeft}`}
          </Box>
          <Box
            style={{
              color: '#87929D',
              backgroundColor: '#E9EBED',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
            }}
          >
            종료
          </Box>
        </Box>
      );
    }
  }

  function showButton() {
    if (props.task.task_status == '진행중') {
      if (props.task.task_submit) {
        return (
          <Button
            style={{
              color: '#00C500',
              fontSize: '0.875rem',
              border: '1px solid #00C500',
              borderRadius: '1.625rem',
              padding: '0.7rem 0rem',
              width: '6rem',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
            onClick={() => {
              navigate('/writing2', {
                state: {
                  task_id: props.task.task_id,
                },
              });
            }}
          >
            수정하기
          </Button>
        );
      }
      return (
        <Button
          style={{
            color: '#FF9900',
            fontSize: '0.875rem',
            border: '1px solid #FF9900',
            borderRadius: '1.625rem',
            padding: '0.7rem 0rem',
            width: '6rem',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
          onClick={() => {
            navigate('/writing2', {
              state: {
                task_id: props.task.task_id,
              },
            });
          }}
        >
          작성하기
        </Button>
      );
    }
    if (!props.task.task_submit) {
      return (
        <Button
          disabled={props.task.task_share == '1'}
          style={{
            display: props.task.task_share == 1 ? 'none' : '',
            color: '#FF9900',
            fontSize: '0.875rem',
            border: '1px solid #FF9900',
            borderRadius: '1.625rem',
            padding: '0.7rem 0rem',
            width: '6rem',
            boxShadow:
              props.task.task_share == '1'
                ? ''
                : '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
          onClick={() => {
            navigate('/writing2', {
              state: {
                task_id: props.task.task_id,
              },
            });
          }}
        >
          {props.task.task_share == '1' ? '' : '늦은 제출'}
        </Button>
      );
    } else if (props.task.task_share == '1') {
      return (
        <Box style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
          <Button
            style={{
              display: props.task.pdf_url != '' ? '' : 'none',
              color: '#279EFF',
              fontSize: '0.875rem',
              borderRadius: '1.625rem',
              padding: '0.7rem 0rem',
              border: '1px solid #279EFF',
              width: '6rem',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
            onClick={() => {
              window.open(props.task.pdf_url);
            }}
          >
            {'평가 리포트'}
          </Button>

          <Button
            style={{
              color: '#00C500',
              fontSize: '0.875rem',
              border: '1px solid #00C500',
              borderRadius: '1.625rem',
              padding: '0.7rem 0rem',
              width: '6rem',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
            onClick={() => {
              navigate('/result', {
                state: {
                  task_id: props.task.task_id,
                },
              });
            }}
          >
            결과 확인
          </Button>
        </Box>
      );
    }
    return (
      <Box style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
        <Button
          disabled={true}
          style={{
            backgroundColor: '#EBFBF9',
            color: '#00C500',
            border: '1px solid #00C500',
            fontSize: '0.875rem',
            borderRadius: '1.625rem',
            padding: '0.7rem 0rem',
            width: '6rem',
          }}
        >
          제출 완료
        </Button>
      </Box>
    );
  }
  return (
    <Box
      style={{
        display: 'flex',
        // justifyContent: 'start',
        marginTop: '1.5rem',
        paddingBottom: '1.7rem',
        borderBottom: '2px solid #A8B1B7',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.75rem',
          flex: 1,
        }}
      >
        <Box style={{display: 'flex', gap: '0.5rem'}}>{showLabel()}</Box>
        <Box style={{fontSize: '1.125rem', fontWeight: 'bold'}}>
          {'[' + props.task.task_janre + '] ' + props.task.task_name}
        </Box>
        <Box
          style={{
            display: 'initial',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: '40vw',
          }}
        >
          {props.task.task_message}
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        {showButton()}
      </Box>
    </Box>
  );
};
TaskView.propTypes = {
  task: PropTypes.object,
  detailEvent: PropTypes.func,
};
export default TaskView;
