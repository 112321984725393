import {Box, Button} from '@mui/material';
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import FragmentAccountTab1 from 'view/mypage/FragmentAccountTab1';
import FragmentAccountTab2 from 'view/mypage/FragmentAccountTab2';

export const FragmentView = props => {
  const [tabStatus, setTabStatus] = useState(1);

  function showTab() {
    switch (tabStatus) {
      case 1:
        return <FragmentAccountTab1 />;
      case 2:
        return <FragmentAccountTab2 />;

      default:
        return <FragmentAccountTab1 />;
    }
  }
  return (
    <Box>
      <Box style={{fontWeight: 'bold', fontSize: '1.375rem'}}>계정 관리</Box>
      <Box
        style={{
          backgroundColor: '#E6E8EB',
          width: '100%',
          height: '2px',
          marginTop: '1.3rem',
          marginBottom: '1.75rem',
        }}
      />
      <Box
        style={{color: '#87929D', fontSize: '0.875rem', marginBottom: '2rem'}}
      >
        이름을 바꾸면 즉시 반영됩니다. 이전에 발행된 결과 리포트에는 반영되지
        않습니다. 비밀번호는 연결된 모든 선생님이 변경할 수 있습니다.
      </Box>
      <Box style={{display: 'flex'}}>
        <Button
          style={{
            color: tabStatus == 1 ? 'black' : '#ADB4BA',
            borderBottom:
              tabStatus == 1 ? '1px solid black' : '1px solid #F4F6F6',
            borderRadius: 0,
            padding: '0.5rem 2rem',
            whiteSpace: 'nowrap',
          }}
          onClick={() => {
            setTabStatus(1);
          }}
        >
          나의 계정 관리
        </Button>
        <Button
          style={{
            color: tabStatus == 2 ? 'black' : '#ADB4BA',
            borderBottom:
              tabStatus == 2 ? '1px solid black' : '1px solid #F4F6F6',
            borderRadius: 0,
            padding: '0.5rem 2rem',
            whiteSpace: 'nowrap',
          }}
          onClick={() => {
            setTabStatus(2);
          }}
        >
          연결된 선생님
        </Button>
      </Box>
      {showTab()}
    </Box>
  );
};
export default FragmentView;
