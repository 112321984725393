import React from 'react';
import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import ReactWordCloud from 'react-d3-cloud';

const ItemView = props => {
  const cloudColor = [
    '#1f77b4',
    '#aec7e8',
    '#ff7f0e',
    '#ffbb78',
    '#2ca02c',
    '#98df8a',
    '#d62728',
    '#ff9896',
    '#9467bd',
    '#c5b0d5',
    '#8c564b',
    '#c49c94',
    '#e377c2',
    '#f7b6d2',
    '#7f7f7f',
    '#c7c7c7',
    '#bcbd22',
    '#dbdb8d',
    '#17becf',
    '#9edae5',
  ]; // 컬러 수정

  return (
    <Box>
      <ReactWordCloud
        font="Noto Sans KR" // 폰트 수정
        height={500}
        // height={'100%'}
        fontSize={word => {
          const preSize = Math.log2(word.value) * 30;
          if (preSize < 20) {
            return 20;
          } else if (preSize > 80) {
            return 80;
          } else {
            return preSize;
          }
        }}
        spiral="archimedean"
        fontStyle="normal"
        fontWeight="400"
        rotate={0}
        padding={5}
        fill={() => cloudColor[parseInt(Math.random() * cloudColor.length)]}
        random={Math.random}
        data={props.data}
      />
    </Box>
  );
};
ItemView.propTypes = {
  data: PropTypes.array,
};
export default ItemView;
