import React, {useEffect, useState} from 'react';
import {gql, useQuery} from '@apollo/client';
import {StringUtils} from './StringUtils';
import {saveCloseDate} from 'common/reducer/popup';
import {useDispatch, useSelector} from 'react-redux';
import NoticePopup from 'view/popup/Notice';

export function GetRunningPopup() {
  const dispatch = useDispatch();
  const popupReducer = useSelector(state => state.popupReducer);

  const dateOnly = StringUtils.getTodayDateOnly();
  const dateToMin = StringUtils.getTodayDateMinOnly();

  const ACTIVE_POPUP = gql`
    query GetPopups {
      popups(
        filters: {
          expireAt: { gte: "${dateToMin}" }
          launchAt: { lte: "${dateToMin}" }
        }
      ) {
        title
        content
        expireAt
        id
        launchAt
      }
    }
  `;

  const [visibility, setVisibility] = useState(false);
  const [updatedPopups, setUpdatedPopups] = useState([]);
  const {loading, error, data} = useQuery(ACTIVE_POPUP);

  // 팝업 데이터 처리
  useEffect(() => {
    if (
      loading ||
      error ||
      popupReducer.close_date == undefined ||
      popupReducer.close_date == dateOnly
    ) {
      setVisibility(false);
      return;
    }

    if (data && data.popups) {
      const processedPopups = data.popups.filter(popup => {
        if (!popup.content) return false;
        if (!popup.title) return false;
        const firstLine = popup.title;
        if (firstLine.includes('@키위런@')) {
          return true; // @키위런@이 포함된 경우 팝업 표시
        } else if (firstLine.includes('@키위티@')) {
          return false; // @키위티@만 포함된 경우 제외
        }

        return true; // @키위런@, @키위티@ 둘 다 없는 경우 표시
      });
      if (popupReducer.close_date === dateOnly) {
        setVisibility(false);
      }
      setUpdatedPopups(processedPopups);
      setVisibility(processedPopups.length > 0);
    }
  }, [loading, error, data]);

  // 하루 동안 팝업 표시 안 함
  function popupCancelForDay() {
    setVisibility(false);
    dispatch(
      saveCloseDate({
        close_date: dateOnly,
      }),
    );
  }

  // 조건에 따른 UI 렌더링
  if (loading || error || !visibility || updatedPopups.length === 0) {
    return null; // 아무것도 렌더링하지 않음
  }

  return (
    <NoticePopup
      visibility={visibility}
      popupInfo={updatedPopups[0]} // 첫 번째 팝업 정보 전달
      cancelEvent={() => setVisibility(false)} // 팝업 닫기
      cancelDayEvent={popupCancelForDay} // 하루 동안 팝업 표시 안 함
    />
  );
}
