import {
  Box,
  Button,
  TextField,
  IconButton,
  InputAdornment,
} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {modifyAccountInfo, removeLogin} from 'common/reducer/auth';
import React, {useState} from 'react';
import APIUtils from 'common/utils/APIUtils';
import logoGoogle from 'asset/image/logo_google.png';
import logoNaver from 'asset/image/logo_naver.png';
import logoKakao from 'asset/image/logo_kakao.png';
import emojiRegex from 'emoji-regex';
import PropTypes from 'prop-types';
import AccountLeavePopup from 'view/popup/AccountLeave';
import {useNavigate} from 'react-router-dom';

export const FragmentView = props => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authReducer = useSelector(state => state.authReducer);

  const [accountLeaveVisibility, setAccountLeaveVisibility] = useState(false);
  function accountLeaveCancel() {
    setAccountLeaveVisibility(false);
  }
  function accountLeaveComplete() {
    setAccountLeaveVisibility(false);
    dispatch(removeLogin());
    navigate('/');
  }
  const [nameText, setNameText] = useState(authReducer.student_name);
  const handleName = event => {
    let inputVal = event.target.value;
    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      if (inputVal.length > 15) {
        inputVal = inputVal.substring(0, 15);
      }
      setNameText(inputVal);
    } else {
      alert('이모지는 입력이 불가능합니다.');
    }
  };
  const [currentPW, setCurrentPW] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(prev => !prev);
  };
  const handleCurrentPW = event => {
    let inputVal = event.target.value;
    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      if (inputVal.length > 15) {
        inputVal = inputVal.substring(0, 15);
        setModifyResultMessage('15자 이상 입력하실 수 없습니다.');
        setModifyResultColor('#FF0000');
      }
      setCurrentPW(inputVal);
    } else {
      alert('이모지는 입력이 불가능합니다.');
    }
  };
  const [newPW, setNewPW] = useState('');
  const [confirmPW, setConfirmPW] = useState('');
  const handleNewPW = event => {
    const inputValue = event.target.value;
    const filteredValue = inputValue
      .replace(/[^a-zA-Z0-9!@#$%^&*()\-_=+[\]{}|;:',.<>?/]/g, '')
      .substring(0, 15); // 영문자와 숫자만 남기기
    setNewPW(filteredValue);
  };

  const handleConfirmPW = event => {
    let inputVal = event.target.value;
    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      if (inputVal.length > 20) {
        inputVal = inputVal.substring(0, 20);
        setModifyResultMessage('20자 이상 입력하실 수 없습니다.');
        setModifyResultColor('#FF0000');
      }
      setConfirmPW(inputVal);
    } else {
      alert('이모지는 입력이 불가능합니다.');
    }
  };
  const [modifyResultMessage, setModifyResultMessage] = useState('');
  const [modifyResultColor, setModifyResultColor] = useState('');

  function saveName() {
    if (nameText != authReducer.student_name) {
      const modifyResult = async (student_id, new_name) => {
        try {
          const response = await APIUtils.AccountModify(student_id, new_name);
          if (response.data.ret_code == 1000) {
            dispatch(
              modifyAccountInfo({
                student_name: new_name,
              }),
            );
          }
        } catch (err) {
          console.log(err);
        }
      };
      modifyResult(authReducer.student_id, nameText);
    }
  }
  function savePassword() {
    const currentPW = '';
    if (newPW != '' && confirmPW != '') {
      const reg = new RegExp('(?=.*[a-zA-Z])(?=.*[0-9]).{5,20}$');
      if (newPW.length < 5) {
        setModifyResultMessage(
          '실패 : 5글자 이상 입력해야 합니다.  비밀번호가 바뀌지 않았습니다.',
        );
        setModifyResultColor('#FF0000');
        return;
      } else if (newPW != confirmPW) {
        setModifyResultMessage('새 비밀번호가 일치하지 않습니다.');
        setModifyResultColor('#FF0000');
        return;
      }
      const pwChangeApi = async (student_id, currPW, newPW) => {
        try {
          const response = await APIUtils.AccountPasswordChange(
            student_id,
            currPW,
            newPW,
          );
          if (response.data.ret_code == 1000) {
            if (showPassword) {
              setModifyResultMessage(
                '성공 : 비밀번호가 [' + newPW + ']로 변경되었습니다.',
              );
            } else {
              setModifyResultMessage('성공 : 비밀번호가 변경되었습니다.');
            }
            setModifyResultColor('blue');
            setNewPW('');
            setConfirmPW('');
          } else if (response.data.ret_code == 2004) {
            setModifyResultMessage(
              '실패 : 현재 비밀번호를 다시 확인하세요. 잘못 입력했습니다.',
            );
            setModifyResultColor('red');
          }
        } catch (err) {
          console.log(err);
        }
      };
      pwChangeApi(authReducer.student_id, currentPW, newPW);
    }
    setModifyResultMessage('');
    setModifyResultColor('');
  }
  function regexTest() {
    const reg = new RegExp('^(?=.*[a-zA-Z])(?=.*[0-9]).{6,20}$');
    return reg.test(currentPW);
  }
  function getAccountLogo() {
    if (authReducer.path == 'google') {
      return (
        <img
          src={logoGoogle}
          alt={'logoGoogle'}
          style={{
            height: '30px',
            borderRadius: '30px',
            boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.15)',
          }}
        />
      );
    } else if (authReducer.path == 'naver') {
      return (
        <img
          src={logoNaver}
          alt={'logoNaver'}
          style={{
            height: '30px',
            borderRadius: '30px',
            boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.15)',
          }}
        />
      );
    } else if (authReducer.path == 'kakao') {
      return (
        <img
          src={logoKakao}
          alt={'logoKakao'}
          style={{
            height: '30px',
            borderRadius: '30px',
            boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.15)',
          }}
        />
      );
    }
    return <></>;
  }
  return (
    <Box style={{marginTop: '3rem'}}>
      <AccountLeavePopup
        visibility={accountLeaveVisibility}
        sentEmail={authReducer.student_email}
        completeEvent={accountLeaveComplete}
        cancelEvent={accountLeaveCancel}
      />
      <Box
        style={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          gap: '6rem',
        }}
      >
        <Box
          style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}
        >
          <Box
            style={{
              marginLeft: '0rem',
              fontSize: '1.125rem', // 원래 폰트 크기로 복구
              fontWeight: 'bold',
              whiteSpace: 'nowrap', // 텍스트 줄바꿈 방지}}>
            }}
          >
            현재 계정
          </Box>
          <Box
            style={{
              display: authReducer.path == 'school' ? 'none' : '',
              marginLeft: '2.2rem',
              fontSize: '1.125rem', // 원래 폰트 크기로 복구
              fontWeight: 'bold',
              whiteSpace: 'nowrap', // 텍스트 줄바꿈 방지}}>
            }}
          >
            {getAccountLogo()} {authReducer.student_email}
          </Box>
          <Box
            style={{
              display: authReducer.path == 'school' ? '' : 'none',
              marginLeft: '2.2rem',
              fontSize: '1.125rem', // 원래 폰트 크기로 복구
              fontWeight: 'bold',
              whiteSpace: 'nowrap', // 텍스트 줄바꿈 방지}}>
            }}
          >
            ID : {authReducer.student_email}
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center', // 수직 중앙 정렬
          justifyContent: 'start', // 요소 간 간격 조정
          marginTop: '2rem', // 전체 상단 여백
          marginBottom: '1rem', // 전체 하단 여백
          gap: '1rem', // 요소 간 간격
        }}
      >
        <Box
          style={{
            fontSize: '1.125rem', // 원래 폰트 크기로 복구
            fontWeight: 'bold',
            whiteSpace: 'nowrap', // 텍스트 줄바꿈 방지
          }}
        >
          사용자 이름
        </Box>
        <Box style={{position: 'relative', width: '300px'}}>
          <TextField
            autoComplete="off"
            variant="outlined"
            label={nameText === '' ? '이름을 입력하세요.' : ''}
            InputProps={{
              style: {
                backgroundColor: '#F7F8FA',
                border: '1px solid #E9EBED',
                fontSize: '1rem', // 입력 필드 글씨 크기 유지
                borderRadius: '0.5rem', // 테두리 둥글기 조정
                height: '2.5rem', // 필드 높이
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: '0.875rem', // 라벨 글씨 크기 유지
                position: 'absolute', // 라벨을 절대 위치로 설정
                top: '50%', // 중앙 정렬
                marginLeft: '1rem',
                transform: 'translateY(-50%)', // 세로 중앙 정렬
              },
              shrink: false,
            }}
            value={nameText}
            onChange={handleName}
            style={{
              width: '100%', // 입력창 너비 설정
            }}
          />
          <Box
            style={{
              position: 'absolute',
              bottom: '0.5rem', // 입력창 아래에 표시
              right: '0.5rem',
              fontSize: '0.75rem',
              color: '#ADB4BA',
            }}
          >
            {nameText.length}/15
          </Box>
        </Box>
        <Button
          style={{
            color: '#00C500',
            fontSize: '0.875rem',
            border: '1px solid #00C500',
            borderRadius: '1.625rem',
            padding: '0.7rem 0rem',
            width: '6rem',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
          onClick={saveName}
        >
          이름 변경
        </Button>
      </Box>
      <Box
        style={{
          marginTop: '1rem',
          fontSize: '1rem',
          color: '#2F3941',
        }}
      >
        선생님과 평가 리포트에 표시되는 이름 :{' '}
        <span style={{fontWeight: 'bold', fontSize: '1.2rem'}}>
          {nameText || 'OOO'}
        </span>
      </Box>

      <Box
        style={{
          display: authReducer.path == 'school' ? '' : 'none',
          marginTop: '5rem',
        }}
      >
        연결된 선생님이 비밀번호를 ID와 동일하게 (
        <font color="red"> {authReducer.student_email} </font>) 변경할 수
        있습니다. 비밀번호를 보려면 🙈를 누르세요. 😊
        <Box
          style={{
            display: 'flex',
            alignItems: 'center', // 수직 중앙 정렬
            justifyContent: 'start', // 요소 간 간격 조정
            marginTop: '2rem', // 전체 상단 여백
            marginBottom: '1rem', // 전체 하단 여백
            gap: '1rem', // 요소 간 간격
          }}
        >
          <Box
            style={{
              fontSize: '1.125rem', // 원래 폰트 크기로 복구
              fontWeight: 'bold',
              whiteSpace: 'nowrap', // 텍스트 줄바꿈 방지
            }}
          >
            새로운 비밀번호
          </Box>
          <Box style={{position: 'relative', width: '500px'}}>
            <TextField
              autoComplete="off"
              variant="outlined"
              type={showPassword ? 'text' : 'password'}
              label={newPW == '' ? '비밀번호 (영어, 숫자 5글자 이상)' : ''}
              InputProps={{
                style: {
                  backgroundColor: '#F7F8FA',
                  border: '1px solid #E9EBED',
                  fontSize: '1rem',
                  borderRadius: '0.5rem', // 테두리 둥글기 조정
                  height: '2.5rem', // 필드 높이
                  width: '300px',
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={togglePasswordVisibility}
                      edge="end"
                      aria-label={
                        showPassword ? '비밀번호 숨기기' : '비밀번호 보기'
                      }
                      style={{
                        fontSize: '1.2rem', // 이모티콘 크기 조정
                      }}
                    >
                      {showPassword ? '😊' : '🙈'}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  fontSize: '0.875rem', // 라벨 글씨 크기 유지
                  position: 'absolute', // 라벨을 절대 위치로 설정
                  top: '50%', // 중앙 정렬
                  marginLeft: '1rem',
                  transform: 'translateY(-50%)', // 세로 중앙 정렬
                },
                shrink: false,
              }}
              value={newPW}
              onChange={handleNewPW}
            />
            <Box
              style={{
                position: 'absolute',
                bottom: '1.0rem', // 입력창 아래에 표시
                right: '0.5rem',
                left: '16.5rem',
                fontSize: '0.75rem',
                color: '#ADB4BA',
              }}
            >
              {newPW.length}/15
            </Box>
            <TextField
              autoComplete="off"
              variant="outlined"
              type={showPassword ? 'text' : 'password'}
              label={newPW == '' ? '비밀번호 확인 ' : ''}
              InputProps={{
                style: {
                  backgroundColor: '#F7F8FA',
                  border: '1px solid #E9EBED',
                  fontSize: '1rem',
                  borderRadius: '0.5rem', // 테두리 둥글기 조정
                  height: '2.5rem', // 필드 높이
                  width: '300px',
                },
              }}
              InputLabelProps={{
                style: {
                  fontSize: '0.875rem', // 라벨 글씨 크기 유지
                  position: 'absolute', // 라벨을 절대 위치로 설정
                  top: '50%', // 중앙 정렬
                  marginLeft: '1rem',
                  transform: 'translateY(-50%)', // 세로 중앙 정렬
                },
                shrink: false,
              }}
              value={confirmPW}
              onChange={handleConfirmPW}
            />
            <Box
              style={{
                position: 'absolute',
                bottom: '0.5rem', // 입력창 아래에 표시
                right: '0.5rem',
                left: '16.5rem',
                fontSize: '0.75rem',
                color: '#ADB4BA',
              }}
            >
              {confirmPW.length}/15
            </Box>
            <Button
              style={{
                color: '#00C500',
                fontSize: '0.875rem',
                border: '1px solid #00C500',
                borderRadius: '1.625rem',
                padding: '0.7rem 0rem',
                width: '7rem',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                marginLeft: '1rem',
                marginTop: '-2rem',
              }}
              onClick={() => {
                savePassword();
              }}
            >
              비밀번호 변경
            </Button>
          </Box>
        </Box>
        <Box
          style={{
            marginTop: '1rem',
            fontSize: '1rem',
            color: modifyResultColor,
          }}
        >
          {modifyResultMessage}
        </Box>
      </Box>
      <Box
        style={{
          marginTop: '6rem', // 전체 상단 여백
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            gap: '6rem',
          }}
        >
          <Box
            style={{
              display: authReducer.path == 'school' ? 'none' : '',
            }}
          >
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box>{getAccountLogo()}</Box>
              <Box style={{marginLeft: '2rem', fontSize: '1.34rem'}}>
                {authReducer.student_email}
              </Box>

              <Button
                style={{
                  color: 'black',
                  fontSize: '0.875rem',
                  fontWeight: 'bold',
                  borderRadius: '2rem',
                  border: '1.5px solid #e9ebed',
                  backgroundColor: '#e9ebed',
                  padding: '0.8rem 1.5rem',
                  whiteSpace: 'nowrap',
                  left: '4rem',
                }}
                onClick={() => {
                  setAccountLeaveVisibility(true);
                }}
              >
                탈퇴하기
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default FragmentView;
