import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Grid, TextField, Box, IconButton, Button} from '@mui/material';
import KeewiChip from 'view/result/KeewiChip';
import {CommentBox} from 'view/common/Components';

const ItemView = props => {
  return (
    <Box
      style={{
        textDecorationColor: 'black',
        textDecorationThickness: '1px',
      }}
    >
      <CommentBox>
        <>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'start',
            }}
          >
            <Box>
              <span style={{color: '#FF9900', fontSize: 'bold'}}>
                {props.commentID == -1 ? '' : '(' + props.commentID + ') '}
              </span>
              {props.A}
            </Box>
          </Box>
          <Box
            style={{
              backgroundColor: 'black',
              height: '1px',
              marginTop: '0.2rem',
            }}
          ></Box>
          <Box
            style={{
              display: 'flex',
              marginTop: '0.5rem',
              marginLeft: '0.5rem',
              marginRight: '0.5rem',
              color: '#68737D',
            }}
          >
            <Box>
              <KeewiChip subtype={props.subtype} />
            </Box>
            <span
              style={{
                marginLeft: '0.5rem',
                whiteSpace: 'pre-wrap', // 줄바꿈 및 공백 보존
              }}
            >
              {props.C}
            </span>
          </Box>
        </>
      </CommentBox>
    </Box>
  );
};
ItemView.propTypes = {
  paragraphIndex: PropTypes.number,
  idx: PropTypes.number,
  commentID: PropTypes.number,
  A: PropTypes.string,
  C: PropTypes.string,
  subtype: PropTypes.string,
};
export default ItemView;
