import React, {useEffect, useState} from 'react';
import {
  Box,
  Grid,
  TextField,
  Button,
  CircularProgress,
  IconButton,
} from '@mui/material';
import {
  categoryTextBox,
  overallBox,
  overallLeftBox,
  overallTextBox,
} from 'view/result/Styles';
import {useLocation} from 'react-router-dom';
import APIUtils from 'common/utils/APIUtils';
import {AnalysisText} from 'view/result/AnalysisText';
import {StringUtils} from 'common/utils/StringUtils';
import {
  MarginBoxComponent,
  TitleComponent3,
  WhiteBox,
} from 'view/common/Components';
import ApiLoading from 'view/common/ApiLoading';
import CommentItem from 'view/comment/CommentItem';
import HeaderView from 'view/common/Header';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import FooterView from 'view/common/Footer';
import PDFIcon from 'asset/imageV2/icon_pdf.svg';
import ChatIcon from 'asset/imageV2/icon_chat.svg';
import RadarChart from 'view/common/RadarChart';
import KeewiCloud from 'view/common/KeewiCloud';
import OverallScore from 'view/grade/OverallScore';

const MainPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);
  const [commentParagraph, setCommentParagraph] = useState([]);

  const [loading, setLoading] = useState(true);

  const [dateText, setDateText] = useState('');
  const [titleText, setTitleText] = useState('');
  const [nameText, setNameText] = useState('');
  const [labelText, setLabelText] = useState('');
  const [analyText, setAnalyText] = useState('');

  const [taskDateText, setTaskDateText] = useState('');
  const [taskDueText, setTaskDueText] = useState('');
  const [taskTitleText, setTaskTitleText] = useState('');
  const [taskMessage, setTaskMessage] = useState('');

  const [textCountChecked, setTextCountChecked] = useState(false);
  const [textCountMin, setTextCountMin] = useState('');
  const [textCountMax, setTextCountMax] = useState('');

  const [requiredWordChecked, setRequiredWordChecked] = useState(false);
  const [requiredWord, setRequiredWord] = useState('');

  const [showScore, setShowScore] = useState(true);
  const [pdfUrl, setPdfUrl] = useState('');

  const [tScore, setTScore] = useState(0);

  const [overallComment, setOverallComment] = useState('');
  const [isGPT, setIsGPT] = useState(false);

  const [keewiScore, setKeewiScore] = useState({
    겹치는단어비율: 0,
    단어의평균길이: 0,
    문단별문장수: 0,
    문장별단어수: 0,
    분석오류문장수: 0,
    전체글자수: 0,
    전체문장수: 0,
    전체문단수: 0,
    흐름이자연스러운정도: 0,
    긴문장의수: 0,
    독자나이: 0,
  });
  const [radarData, setRadarData] = useState({
    labels: [],
    datasets: [],
  });
  const [wordCloudData, setWordCloudData] = useState([
    {text: '키위', value: 1},
    {text: '글쓰기', value: 2},
    {text: '평가', value: 3},
    {text: '엔진', value: 4},
    {text: '피드백', value: 5},
  ]);

  function transformWordCloudData(wordCloud) {
    // 원본 데이터의 weight 합계 계산
    const totalWeight = wordCloud
      .slice(0, 40)
      .reduce((sum, item) => sum + item.weight, 0);

    // weight를 100으로 정규화 (스케일링)
    const transformedData = wordCloud.slice(0, 40).map(item => ({
      text: item.name,
      value: (item.weight / totalWeight) * 100,
    }));

    // 변환된 데이터를 설정
    setWordCloudData(transformedData);
  }

  function getTaskInfo(task_id) {
    const taskResult = async () => {
      try {
        const response = await APIUtils.TaskCall(
          authReducer.student_id,
          task_id,
        );
        if (response.status == 200 && response.data.ret_code == 1000) {
          setNameText(response.data.teacher_name);
          setTaskDateText(
            StringUtils.getDateStringKr(response.data.task_start_time) +
              '~' +
              StringUtils.getDateStringKr(response.data.task_end_time),
          );
          setTaskDueText(
            StringUtils.getTimeStringKr(response.data.task_start_time) +
              ' ~ ' +
              StringUtils.getTimeStringKr(response.data.task_end_time),
          );
          setTaskTitleText(
            '[' + response.data.task_janre + '] ' + response.data.task_name,
          );
          setTaskMessage(response.data.task_message);
          setTitleText(response.data.title);
          setTextCountChecked(response.data.length_check == 1);
          setTextCountMin(response.data.min_length);
          setTextCountMax(response.data.max_length);
          setRequiredWordChecked(response.data.keyword_check == 1);
          setRequiredWord(response.data.keyword);
        }
      } catch (err) {
        console.log(err);
      }
    };
    taskResult();
  }
  function setAnalysisInfoFromKeewiApiLoad(res) {
    setTitleText(res.data.eval_result.title);
    // setNameText(res.data.eval_result.student_name);
    setShowScore(res.data.open_score);
    setPdfUrl(res.data.pdf_url);

    const keewiScore = {
      겹치는단어비율: res.data.eval_result.겹치는단어비율,
      단어의평균길이: res.data.eval_result.단어의평균길이,
      문단별문장수: res.data.eval_result.문단별문장수,
      문장별단어수: res.data.eval_result.문장별단어수,
      분석오류문장수: res.data.eval_result.분석오류문장수,
      전체글자수: res.data.eval_result.전체글자수,
      전체문장수: res.data.eval_result.전체문장수,
      전체문단수: res.data.eval_result.전체문단수,
      흐름이자연스러운정도: res.data.eval_result.흐름이자연스러운정도,
      긴문장의수: res.data.eval_result.긴문장의수 ?? 0,
      독자나이: res.data.eval_result.독자나이 ?? 10,
    };
    setKeewiScore(keewiScore);

    setCommentParagraph(
      AnalysisText.createCommentFromSaved(
        res.data.eval_result.originalText,
        res.data.eval_result.commentList,
      ),
    );
    setLabelText(AnalysisText.createHtmlTextLabel(res.data.eval_result.type));

    setAnalyText(
      AnalysisText.createHtmlTextAnaly(
        res.data.eval_result.paragraph_texts,
        res.data.eval_result.type,
      ),
    );

    setTScore(res.data.eval_result.score);
    setOverallComment(res.data.eval_result.comment);
    setIsGPT(res.data.eval_result.is_gpt_text);

    let datasets = [];
    datasets = [
      {
        data: [
          AnalysisText.getNumericScore(res.data.eval_result.trait_1_score),
          AnalysisText.getNumericScore(res.data.eval_result.trait_2_score),
          AnalysisText.getNumericScore(res.data.eval_result.trait_3_score),
          AnalysisText.getNumericScore(res.data.eval_result.trait_4_score),
          AnalysisText.getNumericScore(res.data.eval_result.trait_5_score),
          AnalysisText.getNumericScore(res.data.eval_result.trait_6_score),
        ],
        fill: true,
        backgroundColor: ({chart: {ctx}}) => {
          const bg = ctx.createLinearGradient(90, 90, 200, 200);
          bg.addColorStop(0, 'rgba(178, 223, 51, 0.5)');
          bg.addColorStop(1, 'rgba(255, 153, 0, 0.5)');
          return bg;
        },
        borderColor: '#FF9900',
        borderWidth: 1,
        pointStyle: 'circle',
        pointBackgroundColor: 'rgba(255, 138, 0, 1)',
        pointBorderColor: 'rgba(255, 255, 255, 0)',
      },
    ];
    const newRadarData = {
      labels: ['문법', '어휘', '표현', '문장', '조직', '주제'],
      datasets: datasets,
    };
    setRadarData(newRadarData);
    transformWordCloudData(res.data.eval_result.word_cloud); // 데이터를 변환하고 상태에 설정
  }
  function createWholeStatisticsText() {
    let flowLevel = '어려움';
    if (keewiScore.흐름이자연스러운정도 >= 71) flowLevel = '쉬움';
    else if (keewiScore.흐름이자연스러운정도 >= 63) flowLevel = '보통';
    return (
      <Box style={{width: '90%', marginBottom: '3rem'}}>
        <Box>
          {createStatisticsText('전체 글자 수', keewiScore.전체글자수, '자')}
        </Box>
        <Box>
          {createStatisticsText('전체 문단 수', keewiScore.전체문단수, '개')}
        </Box>
        <Box>
          {createStatisticsText('전체 문장 수', keewiScore.전체문장수, '개')}
        </Box>
        <Box>
          {createStatisticsText(
            '문단별 문장 수',
            keewiScore.문단별문장수,
            '개',
          )}
        </Box>
        <Box>
          {createStatisticsText(
            '긴 문장(50자)의 수',
            keewiScore.긴문장의수,
            '개',
          )}
        </Box>
        <Box>
          {createStatisticsText('예상 독자 나이', keewiScore.독자나이, '세')}
        </Box>
        <Box>{createStatisticsText('읽기 쉬운 정도', flowLevel, '')}</Box>
      </Box>
    );
  }
  function createStatisticsText(title, score, rest) {
    return (
      <Box
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '0.4rem',
          fontSize: '1rem',
          fontWeight: '500',
        }}
      >
        <Box>{title}</Box>
        <Box>
          <span style={{color: '#f67f10'}}>{score}</span> {rest}
        </Box>
      </Box>
    );
  }
  function createScoreAndComment() {
    if (showScore == undefined || showScore) {
      return (
        <Box style={{display: 'flex', gap: '1rem'}}>
          <Box style={{width: '30%'}}>
            <OverallScore tScore={tScore} />
          </Box>
          {createOverallComment()}
        </Box>
      );
    }
    return createOverallComment();
  }
  function createOverallComment() {
    return (
      <Box style={overallBox}>
        <Box style={overallLeftBox}>총평</Box>
        <Box
          style={{
            backgroundColor: '#D8DCDE',
            height: '2px',
            width: '100%',
            margin: '1rem 0rem',
          }}
        ></Box>
        <Box style={overallTextBox}>{overallComment}</Box>
      </Box>
    );
  }

  function showOpenOverlay() {
    return (
      <Box
        style={{
          // width: '100%',
          paddingBottom: '2rem',
          position: 'fixed',
          bottom: 0,
          right: '2rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'end',
          zIndex: 3,
        }}
      >
        <IconButton
          style={{
            display: pdfUrl == '' ? 'none' : 'flex',
            flexDirection: 'column',
            backgroundColor: '#279EFF',
            border: 'solid 2px #279EFF',
            width: '5rem',
            height: '5rem',
            color: 'white',
            fontSize: '0.8rem',
          }}
          onClick={() => {
            window.open(pdfUrl);
          }}
        >
          <img src={PDFIcon} alt="PDFIcon" />
          PDF <br></br>다운로드
        </IconButton>
      </Box>
    );
  }
  function showGPTValidation() {
    if (isGPT) {
      return (
        <Box
          style={{
            width: '100%',
            backgroundColor: '#f5d7da',
            color: '#bb0000',
            fontSize: '1rem',
            padding: '0.5rem',
            textAlign: 'center',
          }}
        >
          ⚠ GPT로 생성한 글일 가능성이 높습니다.
        </Box>
      );
    }
  }
  useEffect(() => {
    let inputTaskID = 0;
    if (
      location.state == null &&
      window.localStorage.getItem('task_id') == null
    ) {
      navigate('/', {replace: true});
      return;
    } else if (location.state == null) {
      inputTaskID = Number(window.localStorage.getItem('task_id'));
    } else {
      inputTaskID = location.state.task_id;
      window.localStorage.setItem('task_id', location.state.task_id);
    }
    setDateText(StringUtils.getTodayDateKr());
    getTaskInfo(inputTaskID);
    const setAnalasisResult = async task_id => {
      try {
        const response = await APIUtils.TaskResult(
          authReducer.student_id,
          task_id,
        );
        setAnalysisInfoFromKeewiApiLoad(response);
      } catch (err) {
        console.log(err);
      }
    };
    setAnalasisResult(inputTaskID).then(r => {
      setLoading(false);
    });
  }, []);
  // if (onError) return <ServerError />;
  if (loading) return <ApiLoading loading={loading} />;
  return (
    <Box
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <HeaderView />
      <TitleComponent3
        dueText={taskDueText}
        dateText={taskDateText}
        titleText={taskTitleText}
        messageText={taskMessage}
        textCountChecked={textCountChecked}
        textCountMin={textCountMin}
        textCountMax={textCountMax}
        requiredWordChecked={requiredWordChecked}
        requiredWord={requiredWord}
      />
      {showGPTValidation()}
      <MarginBoxComponent>
        <>
          <Box
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              color: '#68737d',
              fontWeight: '700',
              fontSize: '1rem',
              marginTop: '6.5rem',
            }}
          >
            <Box>선생님 이름 | {nameText}</Box>
            <Box style={{flex: 1}}></Box>
            <Box>평가일 | {dateText}</Box>
          </Box>
          <Box
            style={{
              fontSize: '2rem',
              fontWeight: 'bold',
              color: '#2F3941',
              marginTop: '1.5rem',
              marginBottom: '3rem',
              paddingBottom: '3rem',
              borderBottom: '3px solid #E9EBED',
            }}
          >
            {'“'}
            {titleText}
            {'”'}
          </Box>

          {/* <Box style={{display: 'flex', gap: '1rem'}}>
            <Box style={{width: '30%'}}>
              <OverallScore tScore={tScore} />
            </Box>
            {createOverallComment()}
          </Box> */}
          {createScoreAndComment()}
          <Grid container spacing={'1rem'} style={{marginTop: '2rem'}}>
            <Grid
              item
              xs={8}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                style={{
                  fontSize: '1.375rem',
                  fontWeight: '700',
                  marginBottom: '1.5rem',
                }}
              >
                내용 분석
              </Box>
              <WhiteBox>
                <>
                  {labelText}
                  {analyText}
                </>
              </WhiteBox>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                style={{
                  fontSize: '1.375rem',
                  fontWeight: '700',
                  marginBottom: '1.5rem',
                }}
              >
                글 통계 자료
              </Box>
              <WhiteBox>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'start',
                    height: '100%',
                  }}
                >
                  {createWholeStatisticsText()}
                  <Box
                    style={{
                      width: '100%',
                      minHeight: '20rem',
                      backgroundColor: '#F7F8FA',
                      borderRadius: '1.25rem',
                      padding: '1.25rem 0rem',
                      marginBottom: '2rem', // 간격 추가
                    }}
                  >
                    <RadarChart radarData={radarData} />
                  </Box>
                  <Box
                    style={{
                      width: '100%',
                      minHeight: '20rem',
                      backgroundColor: '#F7F8FA',
                      borderRadius: '1.25rem',
                      padding: '1.25rem 0rem',
                      alignItems: 'center',
                      justifyContent: 'center', // 중앙 정렬
                    }}
                  >
                    <KeewiCloud data={wordCloudData} />
                  </Box>
                </Box>
              </WhiteBox>
            </Grid>
          </Grid>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2rem',
              marginTop: '3rem',
            }}
          >
            {commentParagraph.map((paragraph, paragraphIndex) => {
              if (paragraph.length <= 2) {
                return;
              }
              if (paragraph.length == 3 && paragraphIndex == 1) {
                return;
              }
              return paragraph.map((value, idx) => {
                return (
                  <CommentItem
                    key={'commentbox' + paragraphIndex + '_' + idx}
                    paragraphIndex={paragraphIndex}
                    idx={idx}
                    commentID={value.commentID}
                    type={value.type}
                    A={value.A}
                    B={value.B}
                    C={value.C}
                    subtype={value.subtype}
                    text={value.text}
                  />
                );
              });
            })}
          </Box>
        </>
      </MarginBoxComponent>
      {showOpenOverlay()}
      <FooterView />;
    </Box>
  );
};
export default MainPage;
